/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import BackgroundImg from 'gatsby-background-image'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'
import Swiper from 'react-id-swiper'
import { useIntl } from 'gatsby-plugin-react-intl'
import parse from 'html-react-parser'

// Components
import Pagina from '../components/Pagina'
// import counter from '../components/counter'

// CSS, SCSS
import './styles/sustentabilidade.scss'

// Assets
import logo from '../images/sustentabilidade/icons/Sustentabilidade_4.svg'
import people from '../images/sustentabilidade/icons/Sustentabilidade_5.svg'
import heart from '../images/sustentabilidade/icons/Sustentabilidade_6.svg'
import peopleTrash from '../images/sustentabilidade/icons/Sustentabilidade_7.svg'
import chapeu from '../images/sustentabilidade/icons/Sustentabilidade_8.svg'
import heartBeat from '../images/sustentabilidade/icons/Sustentabilidade_9.svg'
import running from '../images/sustentabilidade/icons/Sustentabilidade_10.svg'
import leaf from '../images/sustentabilidade/icons/Sustentabilidade_11.svg'
import mask from '../images/sustentabilidade/icons/Sustentabilidade_12.svg'
import increase from '../images/sustentabilidade/icons/Sustentabilidade_13.svg'
import lwart from '../images/sustentabilidade/icons/logo-lwart.svg'
import meioAmbiente from '../images/sustentabilidade/icons/meioAmbiente.svg'
import sociedade from '../images/sustentabilidade/icons/sociedade.svg'
import governanca from '../images/sustentabilidade/icons/governanca.svg'
import folha from '../images/sustentabilidade/icons/folha.svg'
import icone1 from '../images/sustentabilidade/icons/barris-oleorefinados.svg'
import icone2 from '../images/sustentabilidade/icons/barris-petroleo.svg'
import icone3 from '../images/sustentabilidade/icons/emissoes-refino.svg'
import icone4 from '../images/sustentabilidade/icons/emissoes-queima.svg'

import Counter from '../components/counter/counter'

const BannerSustentabilidade = ({ desktop, mobile }) => {
  const controls = useAnimation()
  const [ref, inView] = useInView()
  const intl = useIntl()

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])

  const image = getImage(desktop)
  const bgImage = convertToBgImage(image)
  const imageMobile = getImage(mobile)
  const bgImageMobile = convertToBgImage(imageMobile)

  return (
    <>
      <motion.div ref={ref} animate={controls}>
        <BackgroundImg
          {...bgImage}
          Tag="div"
          className="paralaxImage d-lg-block d-none"
        >
          <div className="container pt-5">
            <div className="row justify-content-center">
              <div className="col-lg-12 text-center mt-3">
                <img src={lwart} />
                <h1 className="pt-2 text-white">{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.banner.title_desktop' }))}</h1>
                <p className="text-white">{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.banner.p1' }))}</p>
              </div>
              <div className="col-lg-12 pt-lg-3 px-lg-5 mb-4 mb-lg-0">
                <div className="row px-lg-5 mx-1">
                  <motion.div
                    initial="hidden"
                    transition={{ duration: 2, delay: 0.3 }}
                    variants={{
                      visible: { opacity: 1 },
                      hidden: { opacity: 0 }
                    }} className="col-6 col-md">
                    <div className="contador">
                      <div className="card">
                        <img src={icone1} className='w-45' />
                        <Counter initNumber={6914866} initFinalNumber={8021476} initDate={'Jan 01, 2024 15:00:00'} />
                        <p className='m-0 mb-2 text-center font-Spinnaker text-lwart-gray2 line-height-normal'>{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.banner.barris_oleo' }))}</p>
                      </div>
                    </div>
                  </motion.div>
                  <motion.div
                    initial="hidden"
                    transition={{ duration: 1, delay: 0.6 }}
                    variants={{
                      visible: { opacity: 1 },
                      hidden: { opacity: 0 }
                    }} className="col-6 col-md">
                    <div className="contador">
                      <div className="card">
                        <img src={icone2} />
                        <Counter initNumber={230495537} initFinalNumber={267382521} initDate={'Jan 01, 2024 15:00:00'} />
                        <p className='m-0 text-center font-Spinnaker text-lwart-gray2 line-height-normal'>{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.banner.barris_petroleo' }))}</p>
                      </div>
                    </div>
                  </motion.div>
                  <motion.div
                    initial="hidden"
                    transition={{ duration: 1, delay: 0.9 }}
                    variants={{
                      visible: { opacity: 1 },
                      hidden: { opacity: 0 }
                    }} className="col-6 col-md">
                    <div className="contador">
                      <div className="card">
                        <img src={icone3} />
                        <Counter initNumber={1203485} initFinalNumber={1396083} initDate={'Jan 01, 2024 15:00:00'} />
                        <p className='m-0 text-center font-Spinnaker text-lwart-gray2 line-height-normal'>{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.banner.emissoes' }))}</p>
                      </div>
                    </div>
                  </motion.div>
                  <motion.div
                    initial="hidden"
                    transition={{ duration: 1, delay: 0.9 }}
                    variants={{
                      visible: { opacity: 1 },
                      hidden: { opacity: 0 }
                    }} className="col-6 col-md">
                    <div className="contador">
                      <div className="card">
                        <img src={icone4} />
                        <Counter initNumber={7563362} initFinalNumber={8773753} initDate={'Jan 01, 2024 15:00:00'} />
                        <p className='m-0 text-center font-Spinnaker text-lwart-gray2 line-height-normal'>{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.banner.emissoes_queima' }))}</p>
                      </div>
                    </div>
                  </motion.div>
                </div>
              </div>
              <p className="text-white font-size-perso pt-5">{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.banner.p2' }))}</p>
            </div>
          </div>
        </BackgroundImg>
        <BackgroundImg
          {...bgImageMobile}
          Tag="div"
          className="d-lg-none d-block"
        >
          <div className="container d-flex align-items-center h-100">
            <div className="row justify-content-center pt-lg-5 mt-lg-5">
              <div className="col-lg-12 text-center pt-lg-5 mt-3">
                <h3 className="text-white">{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.banner.title_mobile' }))}</h3>
                <p className="text-white">{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.banner.p1' }))}</p>
              </div>
              <div className="col-lg-12 pt-lg-5 px-lg-5 mb-4 mb-lg-0">
                <div className="row px-lg-5">
                  <motion.div
                    initial="hidden"
                    transition={{ duration: 1.5, delay: 0.5 }}
                    variants={{
                      visible: { opacity: 1 },
                      hidden: { opacity: 0 }
                    }} className="col-12 col-md d-flex justify-content-center">
                    <div className="contador">
                      <div className="card">
                        <img src={icone1} className='w-45' />
                        <Counter initNumber={5779017} initFinalNumber={6604591} initDate={'Jan 01, 2023 15:00:00'} />
                        <p className='m-0 text-center font-Spinnaker text-lwart-gray2 line-height-normal'>{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.banner.barris_oleo' }))}</p>
                      </div>
                    </div>
                  </motion.div>
                  <motion.div
                    initial="hidden"
                    transition={{ duration: 1.5, delay: 0.5 }}
                    variants={{
                      visible: { opacity: 1 },
                      hidden: { opacity: 0 }
                    }} className="col-12 col-md d-flex justify-content-center">
                    <div className="contador">
                      <div className="card">
                        <img src={icone2} />
                        <Counter initNumber={192633899} initFinalNumber={220153027} initDate={'Jan 01, 2023 15:00:00'} />
                        <p className='m-0 text-center font-Spinnaker text-lwart-gray2 line-height-normal'>{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.banner.barris_petroleo' }))}</p>
                      </div>
                    </div>
                  </motion.div>
                  <motion.div
                    initial="hidden"
                    transition={{ duration: 1.5, delay: 0.5 }}
                    variants={{
                      visible: { opacity: 1 },
                      hidden: { opacity: 0 }
                    }} className="col-12 col-md d-flex justify-content-center">
                    <div className="contador">
                      <div className="card">
                        <img src={icone3} />
                        <Counter initNumber={1005798} initFinalNumber={1149483} initDate={'Jan 01, 2023 15:00:00'} />
                        <p className='m-0 text-center font-Spinnaker text-lwart-gray2 line-height-normal'>{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.banner.emissoes' }))}</p>
                      </div>
                    </div>
                  </motion.div>
                  <motion.div
                    initial="hidden"
                    transition={{ duration: 1.5, delay: 0.5 }}
                    variants={{
                      visible: { opacity: 1 },
                      hidden: { opacity: 0 }
                    }} className="col-12 col-md d-flex justify-content-center">
                    <div className="contador">
                      <div className="card">
                        <img src={icone4} />
                        <Counter initNumber={6320990} initFinalNumber={7223989} initDate={'Jan 01, 2023 15:00:00'} />
                        <p className='m-0 text-center font-Spinnaker text-lwart-gray2 line-height-normal'>{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.banner.emissoes_queima' }))}</p>
                      </div>
                    </div>
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
        </BackgroundImg>
      </motion.div>
    </>
  )
}

const PoliticaSustentabilidade = ({ card }) => {
  const controls = useAnimation()
  const [ref, inView] = useInView()
  const intl = useIntl()

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])

  return (
    <motion.section ref={ref} animate={controls} className="full m-0 py-5 py-lg-0">
      <div className="container py-lg-5 pl-lg-5">
        <div className="row ml-lg-2 mx-0 align-items-lg-center align-items-start">
          <div className="col-lg-6 pl-0">
            {/* <motion.h3
              initial="hidden"
              transition={{ duration: 2, delay: 1 }}
              variants={{
                visible: { opacity: 1, x: 0 },
                hidden: { opacity: 0, x: -50 }
              }} className="text-lwart-green mb-3 font-size-1125">{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.politica_sustentabilidade.title1' }))}</motion.h3> */}
            <motion.h2
              initial="hidden"
              transition={{ duration: 2, delay: 1 }}
              variants={{
                visible: { opacity: 1, x: 0 },
                hidden: { opacity: 0, x: -50 }
              }} className="text-lwart-blue mb-3 font-size-2 text-left">{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.politica_sustentabilidade.title2' }))}</motion.h2>
            <motion.p
              initial="hidden"
              transition={{ duration: 2, delay: 1 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 }
              }} className="mb-4 pr-lg-5 line-height-normal">
              {parse(intl.formatMessage({ id: 'paginas.sustentabilidade.politica_sustentabilidade.p1' }))}
            </motion.p>
            <motion.p
              initial="hidden"
              transition={{ duration: 2, delay: 1 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 }
              }} className="mb-4 pr-lg-5 line-height-normal">
              {parse(intl.formatMessage({ id: 'paginas.sustentabilidade.politica_sustentabilidade.p2' }))}
            </motion.p>
            <motion.p
              initial="hidden"
              transition={{ duration: 2, delay: 1 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 }
              }} className="mb-4 pr-lg-5 line-height-normal">
              {parse(intl.formatMessage({ id: 'paginas.sustentabilidade.politica_sustentabilidade.p3' }))}
            </motion.p>
            <a href='https://lwart-static-assets-prd.s3.amazonaws.com/Lwart_Framework_Financ%CC%A7as_Verdes.pdf' target="_blank" rel="noreferrer noopener">
              <button className="btn btn-sustentabilidade-2 mb-2 w-md-82 mb-lg-0">{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.politica_sustentabilidade.button' }))}</button>
            </a>
            <div className='mt-lg-2 mt-0'>
              <a href='https://relatorios.lwart.com.br/relatorio-de-sustentabilidade-de-2023.pdf' target="_blank" rel="noreferrer noopener">
                <button className="btn btn-primary btn-sustentabilidade mb-2 w-md-82 mb-lg-0 p-3">{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.politica_sustentabilidade.link4' }))}</button>
              </a>
            </div>
            <div className='mt-lg-2 mt-0'>
              <a href='https://relatorios.lwart.com.br/relatorio-de-sustentabilidade-de-2022.pdf' target="_blank" rel="noreferrer noopener">
                <button className="btn btn-primary btn-sustentabilidade mb-2 w-md-82 mb-lg-0">{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.politica_sustentabilidade.link3' }))}</button>
              </a>
            </div>
            <div className='mt-lg-2 mt-0'>
              <a href='https://relatorios.lwart.com.br/relatorio-de-sustentabilidade-de-2021.pdf' target="_blank" rel="noreferrer noopener">
                <button className="btn btn-primary btn-sustentabilidade mb-2 w-md-82 mb-lg-0">{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.politica_sustentabilidade.link1' }))}</button>
              </a>
            </div>
            <div className='mt-lg-2 mt-0'>
              <a href='https://relatorios.lwart.com.br/relatorio-de-sustentabilidade-de-2020.pdf' target="_blank" rel="noreferrer noopener">
                <button className="btn btn-primary btn-sustentabilidade mb-3 w-md-82 mb-lg-0">{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.politica_sustentabilidade.link2' }))}</button>
              </a>
            </div>
          </div>
          <div className="col-lg-6 p-0 mt-lg-0 mt-5">
            <div>
              <a href='https://relatorios.lwart.com.br/relatorio-de-sustentabilidade-de-2023.pdf' target="_blank" rel="noreferrer noopener">
                <GatsbyImage image={card} alt="Card Imagem" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </motion.section>
  )
}

const ESG = (data) => {
  const [showMore, setShowMore] = useState()
  const intl = useIntl()

  const ShowMore = (id) => {
    setShowMore(id)
  }

  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  return (
    <div className='d-flex justify-content-center bg-banner-ESG'>
      <motion.section ref={ref} animate={controls} className="full overflow-auto m-0 py-5 mb-5 d-flex align-items-center">
        <div className="container p-lg-4 px-0">
          <div className="row px-4 mx-0 px-lg-4 mx-lg-2 align-items-center justify-content-center">
            <div className="p-0 mb-5 pb-3">
              <h2 className="text-white text-center mb-3">{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.esg.title' }))}</h2>
              <p className='text-white text-center font-size-card'>{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.esg.p1' }))}
              </p>
            </div>
            <motion.div initial="hidden"
              transition={{ duration: 1.5, delay: 0.5 }}
              variants={{
                visible: { opacity: 1, x: 0 },
                hidden: { opacity: 0, x: 100 }
              }} className="col-lg-4 p-0 mb-5 mb-lg-0">
              <div className="float-icon-ESG">
                <img src={meioAmbiente} className="bg-lwart-darkblue" />
              </div>
              <div className="card-ESG">
                <GatsbyImage image={data.data.card1.childImageSharp.gatsbyImageData} alt='duas pessoas com os pés quase tocando um rio pequeno' />
                <div className="divisoria"></div>
                <div className='px-4 pb-5'>
                  <h3 className="text-lwart-darkblue font-size-15 text-nowrap letter-space-normal mt-4 mb-3 px-2">{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.esg.cards.01.title' }))}</h3>
                  <p className="font-Spinnaker text-lwart-gray2 line-height-normal pl-2 pr-4 mb-4">{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.esg.cards.01.text' }))}
                  </p>
                </div>
              </div>
            </motion.div>
            <motion.div initial="hidden"
              transition={{ duration: 1.5, delay: 0.5 }}
              variants={{
                visible: { opacity: 1, x: 0 },
                hidden: { opacity: 0, x: 100 }
              }} className="col-lg-4 p-0 mb-5 mb-lg-0">
              <div className="float-icon-ESG">
                <img src={sociedade} className="bg-lwart-darkblue" />
              </div>
              <div className="card-ESG">
                <GatsbyImage image={data.data.card2.childImageSharp.gatsbyImageData} alt='várias pessoas dando a mão' />
                <div className="divisoria"></div>
                <div className='px-4 pb-5'>
                  <h3 className="text-lwart-darkblue font-size-15 text-nowrap letter-space-normal mt-4 mb-3 px-2">{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.esg.cards.02.title' }))}</h3>
                  <p className="font-Spinnaker text-lwart-gray2 line-height-normal pl-2 pr-4 mb-4">{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.esg.cards.02.text' }))}
                  </p>
                </div>
              </div>
            </motion.div>
            <motion.div initial="hidden"
              transition={{ duration: 1.5, delay: 0.5 }}
              variants={{
                visible: { opacity: 1, x: 0 },
                hidden: { opacity: 0, x: 100 }
              }} className="col-lg-4 p-0 mb-5 mb-lg-0">
              <div className="float-icon-ESG">
                <img src={governanca} className="bg-lwart-darkblue" />
              </div>
              <div className="card-ESG">
                <GatsbyImage image={data.data.card3.childImageSharp.gatsbyImageData} alt='mudas de plantas sendo plantadas' />
                <div className="divisoria"></div>
                <div className='px-4 pb-5'>
                  <h3 className="text-lwart-darkblue font-size-15 text-nowrap letter-space-normal mt-4 mb-3 px-2">{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.esg.cards.03.title' }))}</h3>
                  <p className="font-Spinnaker text-lwart-gray2 line-height-normal pl-2 pr-4 mb-4">{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.esg.cards.03.text' }))}
                  </p>
                </div>
              </div>
            </motion.div>
            <div>
              <a href='https://lwart-static-assets-prd.s3.amazonaws.com/POL%C3%8DTICA_ESG.pdf' target="_blank" rel="noreferrer noopener">
                <button className="btn btn-primary btn-sustentabilidade mt-5 mr-5">{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.esg.button' }))}</button>
              </a>
            </div>
          </div>
        </div>
      </motion.section>
      <div className='d-flex justify-content-center position-relative'>
        <div className='position-absolute ESGInfo' onMouseEnter={() => ShowMore('ESG')} onMouseLeave={() => ShowMore()}>
          <p className='text-white text-center'>{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.esg.green_circle' }))}</p>
        </div>
        {showMore === 'ESG' &&
          <div className='text-center position-absolute ESGInfoHover'>
            <img src={folha} />
            <p className='text-white pt-1'>{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.esg.blue_circle' }))}
            </p>
          </div>
        }
      </div>
    </div>
  )
}

const NossoProjetos = ({ fundol }) => {
  const controls = useAnimation()
  const [ref, inView] = useInView()
  const intl = useIntl()

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  return <>
    <motion.section ref={ref} animate={controls} className="full m-0">
      <div className="container px-0 px-lg-5 pt-lg-0 pt-5">
        <div className="row mx-0 mx-lg-2">
          <div className="col-lg p-0 pb-lg-0 pb-3">
            <img src={people} className="rounded-circle bg-lwart-blue w-25 my-lg-3 mb-3" alt="Icone nossos Projetos" />
            <h3 className="mb-2 text-lwart-green font-size-1125">{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.nossos_projetos.title' }))}</h3>
            <h2 className="mb-3 text-lwart-blue font-size-16">{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.nossos_projetos.title2' }))}</h2>
            <p className="line-height-normal">{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.nossos_projetos.p1' }))}
            </p>
            <p className="line-height-normal">{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.nossos_projetos.p2' }))}
            </p>
            <p className="line-height-normal">{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.nossos_projetos.p3' }))}
            </p>
            <p className="line-height-normal">{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.nossos_projetos.p4' }))}</p>
          </div>
          <div className="col-lg-8 d-none d-lg-block p-0">
            <GatsbyImage image={fundol} alt='' />
            <motion.div
              initial="hidden"
              transition={{ duration: 1, delay: 1 }}
              variants={{
                visible: { opacity: 1, x: 0 },
                hidden: { opacity: 0, x: -50 }
              }} className="position-absolute bg-lwart-lightblue2 p-4 card-projeto-blue">
              <p className="text-white font-size-17 line-height-normal p-3 font-Spinnaker">{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.nossos_projetos.icones.icone1.p1' }))}<br /><span className="text-lwart-green font-size-17 font-Spinnaker">{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.nossos_projetos.icones.icone1.number' }))}<br /></span> {parse(intl.formatMessage({ id: 'paginas.sustentabilidade.nossos_projetos.icones.icone1.p2' }))}</p>
            </motion.div>
            <motion.div
              initial="hidden"
              transition={{ duration: 1, delay: 1 }}
              variants={{
                visible: { opacity: 1, x: 0 },
                hidden: { opacity: 0, x: 50 }
              }} className="position-absolute bg-lwart-darkblue p-4 card-projeto-darkblue">
              <p className="p-3 text-white font-size-1125 line-height-normal font-Spinnaker">
                <p className="text-lwart-green font-size-17 font-Spinnaker m-0">{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.nossos_projetos.icones.icone2.p1' }))}<br /></p>
                {parse(intl.formatMessage({ id: 'paginas.sustentabilidade.nossos_projetos.icones.icone2.p2' }))}
              </p>
            </motion.div>
            <motion.div
              initial="hidden"
              transition={{ duration: 1, delay: 1 }}
              variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: -50 }
              }} className="position-absolute bg-lwart-green p-3 card-projeto-green">
              <p className="px-2 text-lwart-darkblue font-size-2 line-height-normal font-Spinnaker">
                {parse(intl.formatMessage({ id: 'paginas.sustentabilidade.nossos_projetos.icones.icone3.p1' }))}
                <p className="text-white font-size-1 line-height-normal font-Spinnaker">{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.nossos_projetos.icones.icone3.p2' }))}</p>
              </p>
            </motion.div>
          </div>
          <div className="p-0 d-block d-lg-none">
            <motion.div
              initial="hidden"
              transition={{ duration: 1, delay: 1 }}
              variants={{
                visible: { opacity: 1, x: 0 },
                hidden: { opacity: 0, x: -50 }
              }} className="bg-lwart-lightblue2 p-4 card-projeto-blue mb-4">
              <p className="text-white text-center font-size-17 line-height-normal mb-0 font-Spinnaker">Mais de <br /><span className="text-lwart-green font-size-17 font-Spinnaker">60 mil<br /></span> pessoas<br /> alcançadas</p>
            </motion.div>
            <motion.div
              initial="hidden"
              transition={{ duration: 1, delay: 1 }}
              variants={{
                visible: { opacity: 1, x: 0 },
                hidden: { opacity: 0, x: -50 }
              }} className="bg-lwart-darkblue p-4 card-projeto-darkblue mb-4">
              <p className="text-center text-white font-size-1125 line-height-normal font-Spinnaker">
                <p className="text-lwart-green font-size-17 font-Spinnaker m-0">24 projetos<br /></p>
                em áreas como educação, cultura, esporte, saúde, além de doações para entidades assistenciais.
              </p>
            </motion.div>
            <motion.div
              initial="hidden"
              transition={{ duration: 1, delay: 1 }}
              variants={{
                visible: { opacity: 1, x: 0 },
                hidden: { opacity: 0, x: -50 }
              }} className="bg-lwart-green p-4 card-projeto-green mb-5">
              <p className="text-center text-lwart-darkblue font-size-2 line-height-normal font-Spinnaker">
                Mais de<br /> 6 milhões
                <p className="text-white font-size-1 line-height-normal font-Spinnaker">investidos na comunidade</p>
              </p>
            </motion.div>
          </div>
        </div>
      </div>
    </motion.section>
  </>
}

const LideraJovem = ({ photo, logo }) => {
  const intl = useIntl()

  return <>
    <section className="full bg-lwart-darkblue m-0">
      <div className="row m-0">
        <div className="col-lg-6 p-0">
          <GatsbyImage image={photo} alt='' />
        </div>
        <div className="col-lg-5 p-5">
          <div className='container p-lg-5 p-2'>
            <GatsbyImage image={logo} className='img-lidera-jovem' alt='' />
            <h3 className='text-lwart-blue2 pt-5'>{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.lidera_jovem.title1' }))}</h3>
            <p className='text-white pt-2'>{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.lidera_jovem.p1' }))}
            </p>
            <p className='text-white'>{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.lidera_jovem.p2' }))}
            </p>
            <a href='https://www.liderajovem.org/' target='_blank' className='text-lwart-blue2' style={{ textDecoration: 'underline' }} rel="noreferrer">{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.lidera_jovem.link' }))}</a>
          </div>
        </div>
      </div>
    </section>
    <section className="full bg-lwart-green m-0">
      <h4 className='text-white py-4 text-center m-0'>{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.lidera_jovem.title2' }))}</h4>
    </section>
  </>
}

const Iniciativas = (data) => {
  const [showText, setShowText] = useState()
  const intl = useIntl()

  const ShowText = (id) => {
    setShowText(id)
  }

  const MoldeCarousel = props => (
    <div className='boxImage' onMouseEnter={() => ShowText(props.mouseEnter)} onMouseLeave={() => ShowText()}>
      <GatsbyImage image={props.image} alt='' />
      <div className='d-lg-flex d-none'>
        {showText === props.showText &&
          <div className='hover d-flex justify-content-center align-items-center p-5'>
            <div>
              <h4 className='pb-3'>{props.title}</h4>
              <p className='text-white font-Spinnaker line-height-normal'>{props.text}</p>
            </div>
          </div>
        }
      </div>
      <div className='d-lg-none d-flex'>
        <div className='hover justify-content-center align-items-center p-5'>
          <div>
            <h4 className='pb-4'>{props.title}</h4>
            <p className='text-white font-Spinnaker line-height-normal'>{props.text}</p>
          </div>
        </div>
      </div>
    </div>
  )

  const params = {
    spaceBetween: 30,
    slidesPerView: 1,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    breakpoints: {
      1200: {
        slidesPerView: 4,
        spaceBetween: 0
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 0
      },
      660: {
        slidesPerView: 2,
        spaceBetween: 0
      }
    }
  }
  return <>
    <div id='carousel-iniciativas'>
      <Swiper {...params}>
        <div>
          <MoldeCarousel
            mouseEnter='vamosRenovar'
            showText='vamosRenovar'
            image={data.data.iniciativa1.childImageSharp.gatsbyImageData}
            title={parse(intl.formatMessage({ id: 'paginas.sustentabilidade.iniciativas.renovar.title' }))}
            text={parse(intl.formatMessage({ id: 'paginas.sustentabilidade.iniciativas.renovar.text' }))}
          />
        </div>
        <div>
          <MoldeCarousel
            mouseEnter='alba'
            showText='alba'
            image={data.data.iniciativa2.childImageSharp.gatsbyImageData}
            title={parse(intl.formatMessage({ id: 'paginas.sustentabilidade.iniciativas.alba.title' }))}
            text={parse(intl.formatMessage({ id: 'paginas.sustentabilidade.iniciativas.alba.text' }))}
          />
        </div>
        <div>
          <MoldeCarousel
            mouseEnter='eraUmaVez'
            showText='eraUmaVez'
            image={data.data.iniciativa3.childImageSharp.gatsbyImageData}
            title={parse(intl.formatMessage({ id: 'paginas.sustentabilidade.iniciativas.era_uma_vez.title' }))}
            text={parse(intl.formatMessage({ id: 'paginas.sustentabilidade.iniciativas.era_uma_vez.text' }))}
          />
        </div>
        <div>
          <MoldeCarousel
            mouseEnter='teatroMunicipal'
            showText='teatroMunicipal'
            image={data.data.iniciativa4.childImageSharp.gatsbyImageData}
            title={parse(intl.formatMessage({ id: 'paginas.sustentabilidade.iniciativas.teatro_municipal.title' }))}
            text={parse(intl.formatMessage({ id: 'paginas.sustentabilidade.iniciativas.teatro_municipal.text' }))}
          />
        </div>
        <div>
          <MoldeCarousel
            mouseEnter='aHoraConto'
            showText='aHoraConto'
            image={data.data.iniciativa5.childImageSharp.gatsbyImageData}
            title={parse(intl.formatMessage({ id: 'paginas.sustentabilidade.iniciativas.hora_conto.title' }))}
            text={parse(intl.formatMessage({ id: 'paginas.sustentabilidade.iniciativas.hora_conto.text' }))}
          />
        </div>
        <div>
          <MoldeCarousel
            mouseEnter='natalCidadao'
            showText='natalCidadao'
            image={data.data.iniciativa6.childImageSharp.gatsbyImageData}
            title={parse(intl.formatMessage({ id: 'paginas.sustentabilidade.iniciativas.natal_cidadao.title' }))}
            text={parse(intl.formatMessage({ id: 'paginas.sustentabilidade.iniciativas.natal_cidadao.text' }))}
          />
        </div>
        <div>
          <MoldeCarousel
            mouseEnter='transformacao'
            showText='transformacao'
            image={data.data.iniciativa7.childImageSharp.gatsbyImageData}
            title={parse(intl.formatMessage({ id: 'paginas.sustentabilidade.iniciativas.transformacao.title' }))}
            text={parse(intl.formatMessage({ id: 'paginas.sustentabilidade.iniciativas.transformacao.text' }))}
          />
        </div>
      </Swiper>
    </div>
  </>
}

const PoliticasDoacoes = ({ arquivo }) => {
  const controls = useAnimation()
  const [ref, inView] = useInView()
  const intl = useIntl()

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  return (
    <>
      <section className="full m-0">
        <div className="container p-0 p-lg-5 py-5">
          <div className="row px-4 mx-md-2 mx-0">
            <div className="col p-0">
              <div className="d-flex flex-column flex-lg-row align-items-center align-items-lg-start mb-4">
                <img src={heart} className="rounded-circle bg-lwart-green icon-heart mb-3" alt="Coração section Politica" />
                <div className="ml-3 pt-2">
                  <h3 className="text-lwart-green font-size-1125">{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.politicas_doacoes.title1' }))}</h3>
                  <h2 className="text-lwart-blue font-size-17">{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.politicas_doacoes.title2' }))}</h2>
                </div>
              </div>
              <motion.div ref={ref} animate={controls} className="row row-cols-1 row-cols-md-2">
                <div className="col d-flex mb-3 align-items-start">
                  <motion.img
                    initial="hidden"
                    transition={{ duration: 2, delay: 0.5 }}
                    variants={{
                      visible: { opacity: 1 },
                      hidden: { opacity: 0 }
                    }} src={peopleTrash} className="mr-3 icon-politica" />
                  <p className="line-height-normal"><span className="font-weight-bold">{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.politicas_doacoes.cidadania.span' }))}</span> {parse(intl.formatMessage({ id: 'paginas.sustentabilidade.politicas_doacoes.cidadania.p' }))}</p>
                </div>
                <div className="col d-flex mb-3 align-items-start">
                  <motion.img
                    initial="hidden"
                    transition={{ duration: 2, delay: 0.5 }}
                    variants={{
                      visible: { opacity: 1 },
                      hidden: { opacity: 0 }
                    }} src={leaf} className="mr-3 icon-politica" />
                  <p className="line-height-normal"><span className="font-weight-bold">{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.politicas_doacoes.meio_ambiente.span' }))}</span> {parse(intl.formatMessage({ id: 'paginas.sustentabilidade.politicas_doacoes.meio_ambiente.p' }))}</p>
                </div>
                <div className="col d-flex mb-3 align-items-start">
                  <motion.img
                    initial="hidden"
                    transition={{ duration: 2, delay: 0.5 }}
                    variants={{
                      visible: { opacity: 1 },
                      hidden: { opacity: 0 }
                    }} src={chapeu} className="mr-3 icon-politica" />
                  <p className="line-height-normal"><span className="font-weight-bold">{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.politicas_doacoes.educacao.span' }))}</span> {parse(intl.formatMessage({ id: 'paginas.sustentabilidade.politicas_doacoes.educacao.p' }))}</p>
                </div>
                <div className="col d-flex mb-3 align-items-start">
                  <motion.img
                    initial="hidden"
                    transition={{ duration: 2, delay: 0.5 }}
                    variants={{
                      visible: { opacity: 1 },
                      hidden: { opacity: 0 }
                    }} src={mask} className="mr-3 icon-politica" />
                  <p className="line-height-normal"><span className="font-weight-bold">{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.politicas_doacoes.cultura.span' }))}</span> {parse(intl.formatMessage({ id: 'paginas.sustentabilidade.politicas_doacoes.cultura.p' }))}</p>
                </div>
                <div className="col d-flex mb-3 align-items-start">
                  <motion.img
                    initial="hidden"
                    transition={{ duration: 2, delay: 0.5 }}
                    variants={{
                      visible: { opacity: 1 },
                      hidden: { opacity: 0 }
                    }} src={heartBeat} className="mr-3 icon-politica" />
                  <p className="line-height-normal"><span className="font-weight-bold">{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.politicas_doacoes.saude.span' }))}</span> {parse(intl.formatMessage({ id: 'paginas.sustentabilidade.politicas_doacoes.saude.p' }))}</p>
                </div>
                <div className="col d-flex mb-3 align-items-start">
                  <motion.img
                    initial="hidden"
                    transition={{ duration: 2, delay: 0.5 }}
                    variants={{
                      visible: { opacity: 1 },
                      hidden: { opacity: 0 }
                    }} src={increase} className="mr-3 icon-politica" />
                  <p className="line-height-normal"><span className="font-weight-bold">{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.politicas_doacoes.desenvolvimento.span' }))}</span> {parse(intl.formatMessage({ id: 'paginas.sustentabilidade.politicas_doacoes.desenvolvimento.p' }))}</p>
                </div>
                <div className="col d-flex align-items-start">
                  <motion.img
                    initial="hidden"
                    transition={{ duration: 2, delay: 0.5 }}
                    variants={{
                      visible: { opacity: 1 },
                      hidden: { opacity: 0 }
                    }} src={running} className="mr-3 icon-politica" />
                  <p className="line-height-normal"><span className="font-weight-bold">{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.politicas_doacoes.esporte.span' }))}</span> {parse(intl.formatMessage({ id: 'paginas.sustentabilidade.politicas_doacoes.esporte.p' }))}</p>
                </div>
                <div className="col pl-5 mt-3">
                  <a href={arquivo} target="_blank" rel="noreferrer noopener">
                    <button className="btn btn-primary ml-lg-5 btn-sustentabilidade">{parse(intl.formatMessage({ id: 'paginas.sustentabilidade.politicas_doacoes.link' }))}</button>
                  </a>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
const Sustentabilidade = ({ data }) => {
  return <>
    <Pagina pagina={data.pagina} />
    <BannerSustentabilidade desktop={data.paralax.childImageSharp.gatsbyImageData} mobile={data.paralaxMobile.childImageSharp.gatsbyImageData} />
    <PoliticaSustentabilidade card={data.CardSustentabilidade.childImageSharp.gatsbyImageData} arquivo={data.RelatorioSustentabilidade && data.RelatorioSustentabilidade.arquivo.file.url} />
    <ESG data={data} />
    <NossoProjetos fundol={data.fundol.childImageSharp.gatsbyImageData} />
    <LideraJovem photo={data.desenvolvendo.childImageSharp.gatsbyImageData} logo={data.liderajovem.childImageSharp.gatsbyImageData} />
    <Iniciativas data={data} />
    <PoliticasDoacoes arquivo={data.arquivo && data.arquivo.arquivo.file.url} />
    <div className="gradient-blue-to-green"></div>
  </>
}

export default Sustentabilidade

export const query = graphql`{
  RelatorioSustentabilidade: contentfulDocumentos(categoria: {eq: "relatorio-de-sustentabilidade"}) {
    arquivo {
      file {
        url
      }
    }
  }
  arquivo: contentfulDocumentos(categoria: {eq: "sustentabilidade"}) {
    arquivo {
      file {
        url
      }
    }
  }
  pagina: contentfulPagina(path: {eq: "/sustentabilidade/"}) {
    path
    metaTitle
    metaDescription
    pageTitle
    tags
    ogImage {
      localFile {
        publicURL
      }
    }
  }
  CardSustentabilidade: file(
    relativePath: {eq: "sustentabilidade/relatorio-sustentabilidade-2023.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  liderajovem: file(relativePath: {eq: "sustentabilidade/face.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 300, height: 300, layout: CONSTRAINED)
    }
  }
  fundol: file(relativePath: {eq: "sustentabilidade/Fundo L gente.webp"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 669, height: 593, layout: CONSTRAINED)
    }
  }
  card1: file(relativePath: {eq: "sustentabilidade/meio-ambiente.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: BLURRED, layout: FULL_WIDTH)
    }
  }
  card2: file(relativePath: {eq: "sustentabilidade/sociedade.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: BLURRED, layout: FULL_WIDTH)
    }
  }
  card3: file(relativePath: {eq: "sustentabilidade/governanca.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: BLURRED, layout: FULL_WIDTH)
    }
  }
  desenvolvendo: file(relativePath: {eq: "sustentabilidade/desenvolvendo-protagonistas-sociais.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: BLURRED, layout: FULL_WIDTH)
    }
  }
  iniciativa1: file(
    relativePath: {eq: "sustentabilidade/iniciativas/vamos-renovar-juntos.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: BLURRED, layout: FULL_WIDTH)
    }
  }
  iniciativa2: file(
    relativePath: {eq: "sustentabilidade/iniciativas/alba.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: BLURRED, layout: FULL_WIDTH)
    }
  }
  iniciativa3: file(
    relativePath: {eq: "sustentabilidade/iniciativas/era-uma-vez-no-brasil.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: BLURRED, layout: FULL_WIDTH)
    }
  }
  iniciativa4: file(
    relativePath: {eq: "sustentabilidade/iniciativas/teatro-municipal.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: BLURRED, layout: FULL_WIDTH)
    }
  }
  iniciativa5: file(
    relativePath: {eq: "sustentabilidade/iniciativas/a-hora-do-conto.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: BLURRED, layout: FULL_WIDTH)
    }
  }
  iniciativa6: file(
    relativePath: {eq: "sustentabilidade/iniciativas/natal-cidadao.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: BLURRED, layout: FULL_WIDTH)
    }
  }
  iniciativa7: file(
    relativePath: {eq: "sustentabilidade/iniciativas/transformacao-2.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: BLURRED, layout: FULL_WIDTH)
    }
  }
  paralax: file(relativePath: {eq: "sustentabilidade/nossa-atuacao.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  paralaxMobile: file(relativePath: {eq: "sustentabilidade/sustentabilidade.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 400, height: 600, layout: CONSTRAINED)
    }
  }
}
`
